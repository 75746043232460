import React from 'react';
import { useEffect, useState } from 'react';
import './about.css';

const About = () => {

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
      setFadeIn(true); // Trigger the fade-in animation
    }, []);

 
    return(
        <>             
            <div className={`aboutwraper ${fadeIn ? 'fade-in' : ''}`}>
            <div className="mainaboutitle">
                    <h3>Welcome to NovakRog</h3>
                    <h4>Discover works in design and development</h4>
              </div>
            <div className='aboutbackg'>
                <div className="aboutcontainer">
                  <div className="aboutimg"><img src="/img/aboutimg/NovakR_web_b.jpg"></img> </div>
                  <div className="aboutme"> 
                    <h2>About Me</h2>
                      <p className='aboutmedescript'>
                      I'm Novak Roganovikj, a multidisciplinary designer and front-end web developer passionate about creating modern, user-focused digital experiences. Specializing in React, JavaScript, and CMS technologies like WordPress, I combine visual design with clean, efficient code to deliver seamless, interactive websites.
                      <br />Based in Basel, Switzerland, I work locally or remotely to bring your ideas to life. Along with front-end development, I offer multimedia services, including vector illustrations, video animation, and print media design. Whether you need a custom website or creative solutions, let’s collaborate! Contact me via the form or phone +41 77 520 46 81.
                    </p>
                </div>
                </div>
                </div>
              
            </div>

        </>
    )
}
export default About;