import React from 'react';
import './service.css';

const Service = () => {
 
    return(
        <>             
          {/* <div className='servicenovakrog'><h2>About NovakRog</h2></div> */}
            
            
          <div className="pagetitle">
                    <h3>Services I can provide</h3>
                    <h4>Web Code & Motion, Design & Art</h4>
                </div>
 
            <div className='servicewraper'>
                
                <div className="servicecontainerone">
                    <div className="serviceimg"><img src="/img/services/WEB_design_b.jpg"></img> </div>
                    <div className="serviceme"> 
                        <h2>Web Design</h2>
                        <p className='servicemedescript'>
                        I design modern, responsive websites tailored to your brand, 
                        focusing on aesthetics and functionality. My goal is to create user-friendly, 
                        visually appealing experiences.
                        </p>
                    </div>
                </div>

                <div className="servicecontainertwo">
                    <div className="serviceimg"><img src="/img/services/WEB_motion_white.png"></img> </div>
                <div className="serviceme"> 
                <h2>Web Motion</h2>
                <p className='servicemedescript'>
                Bring your website to life with engaging animations and interactive elements. 
                I specialize in creating smooth, dynamic motion to enhance user interaction.
                </p>
                </div>
                
                </div>
              
                <div className="servicecontainertwo">
                    <div className="serviceimg"><img src="/img/services/creative_idea_white.jpg"></img> </div>
                        <div className="serviceme"> 
                            <h2>Creative Design</h2>
                            <p className='servicemedescript'>
                            From logos to complete branding, I bring your ideas to life with fresh and impactful designs. 
                            My work helps your brand stand out across digital and print platforms.
                            </p>
                    </div>
                </div>
               
                <div className="servicecontainerone">
                    <div className="serviceimg"><img src="/img/services/vector_art.jpg"></img> </div>
                        <div className="serviceme"> 
                            <h2>Vector Art</h2>
                            <p className='servicemedescript'>
                            I deliver detailed, scalable vector illustrations for all your needs, from digital projects to print materials. 
                            Whether it’s custom icons or intricate artwork, I ensure precision and creativity.
                            </p>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Service;