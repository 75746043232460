import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './pagination.css';

const Pagination = ({ currentId }) => {
  const items = useSelector((state) => state.items.items);
  const currentIndex = items.findIndex((item) => item.id === parseInt(currentId));
  const totalItems = items.length;

  const prevId = currentIndex > 0 ? items[currentIndex - 1].id : null;
  const nextId = currentIndex < totalItems - 1 ? items[currentIndex + 1].id : null;

  return (
    <div className="pagination">
      {prevId && <Link to={`/overview/${prevId}`}>&lt;</Link>}
      <span>{`${currentIndex + 1}/${totalItems}`}</span>
      {nextId && <Link to={`/overview/${nextId}`}>&gt;</Link>}
    </div>
  );
};

export default Pagination;
