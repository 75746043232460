import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import './headerNav.css';
import humbIcon from "../../icons/hambicon.png";
import xIcon from "../../icons/xicon.png";
import NRSvg from "../../components/svg/nrsvg"

const HeaderNav = ({ setNavOpen, className }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const navresponsive = () => {
        let mainavbarId = document.getElementById("mainavbar");

        if (!isNavOpen) {
            mainavbarId.className += " responsive";
            setNavOpen(true);
        } else {
            mainavbarId.className = "mainnavbar";
            setNavOpen(false);
        }
        setIsNavOpen(!isNavOpen);
    };

    // Function to handle closing the nav when a link is clicked
    const closeNav = () => {
        let mainavbarId = document.getElementById("mainavbar");
        mainavbarId.className = "mainnavbar";
        setNavOpen(false);
        setIsNavOpen(false);
    };

    return (
        <>
            <header className={className}>
                <div className="navigation">
                    <div className="mainnavbar" id="mainavbar">
                        <nav>
                            <div className="navlogo">
                                <object type="image/svg+xml" data="./svg/NRlogo_mobile.svg"></object>
                            </div>
                            <ul>
                                <li><NavLink to="/" onClick={closeNav}>Home</NavLink></li>
                                <li><NavLink to="/about" onClick={closeNav}>About</NavLink></li>
                                <li><NavLink to="/portfolio" onClick={closeNav}>Portfolio</NavLink></li>
                                <li><NavLink to="/service" onClick={closeNav}>Service</NavLink></li>
                                {/* <li><NavLink to="/videos" onClick={closeNav}>Videos</NavLink></li> */}
                                <li><NavLink to="/contact" onClick={closeNav}>Contact</NavLink></li>
                                <li><NavLink to="/login" onClick={closeNav}>Login</NavLink></li>
                            </ul>
                            <div className="hambicon">
                                <a href="#" onClick={navresponsive}>
                                    <img src={isNavOpen ? xIcon : humbIcon} alt="Toggle Icon" />
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
}

export default HeaderNav;
