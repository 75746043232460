import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/home.js'
import About from './pages/about.js'
import Portfolio from './pages/portfolio.js';
import Overview from './pages/overview.js'
import Service from './pages/service.js';
import Videos from './pages/videos.js';
import Contact from './pages/contact.js';
import Login from './pages/login.js';
import HeaderNav from './components/headerNav/headerNav.js';
import Footer from './components/footer/footer.js';
import ScrollToTop from './components/scrolltoTop/scrolltoTop.js';
import React, { useState } from 'react';
import './normalize.min.css';

// import 'https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css'; // Normalize.css
// import 'https://cdnjs.cloudflare.com/ajax/libs/Meyerweb-reset/2.0/reset.min.css'; // CSS Reset*/



function App() {

  const [navOpen, setNavOpen] = useState(false); 
  const location = useLocation();  // Get current path

  return (
    <>
    <ScrollToTop />
    <div className="wrappage"> 
      
      {/*temporary turn off for testing */} 
        <HeaderNav setNavOpen={setNavOpen} className={location.pathname === '/' ? 'homepage-header' : ''}/>
         <div className={`main-content ${navOpen ? 'nav-open': ''}`}> 
         
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />           
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/overview/:id" element={<Overview key={location.pathname} navOpen={navOpen}/>} /> 
              <Route path="/service" element={<Service />} />
              <Route path="/videos" element={<Videos />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
          </Routes>
         </div>
         <Footer className={location.pathname === '/' ? 'homepage-footer' : ''} />  
      </div>
      {/* <Footer className={location.pathname === '/' ? 'homepage-footer' : ''} />   */}

      
    </>
  );
}

export default App;