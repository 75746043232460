import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import QuickNavigation from '../components/quicknav/quicknavigation';
import Pagination from '../components/pagination/pagination';

import './overview.css';

const Overview = ({ navOpen }) => {
  const { id } = useParams();
  const item = useSelector((state) =>
    state.items.items.find((item) => item.id === parseInt(id))
  );

  const renderMedia = (mediaType, img, video) => {
    if (mediaType === 'img' && img && img !== 'null') {
      return <img className="mediaType" src={img} alt={item.title} />;
    }

    if (mediaType === 'video') {
      return (
        <video src={video} type="video/mp4" playsInline>
          Your browser does not support the video tag.
        </video>
      );
    }
    return null;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const targetElement = entry.target;
          setTimeout(() => {
            const video = targetElement.querySelector("video");
            if (entry.isIntersecting) {
              if (video && video.paused) {
                video.muted = true;
                video.play().catch((err) => console.error("Autoplay failed:", err));
              }
              targetElement.classList.add("fadeIn");
            } else {
              targetElement.classList.remove("fadeIn");
              if (video && !video.paused) {
                console.log("VIDEO IS RUNNING!");
                video.pause();
                video.currentTime = 0;
              }
            }
          }, 100);
        });
      },
      { threshold: 0.2 }
    );

    const contentItems = document.querySelectorAll(".contentitem");
    contentItems.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);


  return (
    <>    
    
    <div className="wrapitem">
       
       {/* HERE IS THE QUICKNAV component - Temp turn off / on for  testing purpossses */} 
      
       {/* <div className="quicknav"> */}

       <div className={`overview-page ${navOpen ? 'nav-open' : ''}`}>
        
        {<QuickNavigation navOpen={navOpen}/>}</div> 

      <main className="maincontent">
     
        <div className="contentitem">
          <div className="overmaintitle">
            <h2>{item.title}</h2>
          </div>
          <div className="images">
            {Array.isArray(item.img) ? (
              item.img.map((src, index) => (
                <img key={index} src={src} alt={`${item.title} ${index + 1}`} />
              ))
            ) : (
              <img src={item.img} alt={item.title} />
            )}
          </div>
          <div className="descript">
            <h3>{item.subtitle}</h3>
            <p>{item.overview}</p>
          </div>
        </div>

        <div className="media">    
        <div className="contentitem"> 
            {item.mediaType && renderMedia(item.mediaType, item.img, item.video)}
        </div> 
        </div>

        {item?.design && (
          <div className="contentitem">
            <div className="title">
              <h2>{item?.design}</h2>
            </div>
            {item?.design_img && (
              <div className="images">
                {Array.isArray(item?.design_img) ? (
                  item?.design_img.map((src, index) => (
                    <img key={index} src={src} alt={`${item.title} design ${index + 1}`} />
                  ))
                ) : (
                  <img src={item?.design_img} alt={`${item.title} design`} />
                )}
              </div>
            )}
            <div className="descript">
              <h3>{item?.design_subtitle}</h3>
              <p>{item?.design_descript}</p>
            </div>
          </div>
        )}

        {item?.features && (
          <div className="contentitem">
            <div className="title">
              <h2>{item?.features}</h2>
            </div>

            {item?.features_img && (
              <div className="images">
                <img src={item?.features_img} alt={`${item.title} features`} />
              </div>
            )}
            
            <div className="descript">
              <h3>{item?.features_subtitle}</h3>
              <p>{item?.features_descript}</p>
            </div>
          </div>
        )}

        {item?.technology && (
          <div className="contentitem">
            <div className="title">
              <h2>{item?.technology}</h2>
            </div>
            {item?.tech_img && (
              <div className="techimages">
                {Array.isArray(item.tech_img) ? (
                  item.tech_img.map((src, index) => (
                    <img
                      key={index}
                      src={src}
                      alt={`${item.title} tech ${index + 1}`}
                      style={{ maxWidth: '10%', height: 'auto', padding: '0.2em' }}
                    />
                  ))
                ) : (
                  <img
                    src={item?.tech_img}
                    alt={item?.technology}
                    style={{ minWidth: '75%', height: 'auto' }}
                  />
                )}
              </div>
            )}
            <div className="descript">
              <h3>{item?.technology_subtitle}</h3>
              <p className="techdescript">{item.tech_descript}</p>
            </div>
          </div>
        )}
         
        <Pagination currentId={id} />
       
      </main>
    </div>
    </>
  );
};

export default Overview;
