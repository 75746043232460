import { createSlice } from '@reduxjs/toolkit';
// import NRSvg from '../../components/svg/nrsvg2';


const initialState = {
    items:[
        // { id: 1, name: "Portfolio", thumb_img:"/img/thumb_web1.jpg", type:"web" },
        {
          id: 1,
          name: "NovakRog",
          quickname: "NovakRog",     
          title: "Portfolio Web Site",
          subtitle: "A showcase of scratch design and development",
          type: "web",
          thumb_subtitle: "Dynamic web portfolio",
          // thumb_img: "/img/thumb_novakrog_web.jpg",
          thumb_video:"/img/novakrog_thumb.mp4", 
          quick_nav_img:"/img/thumb_novakrog_web.jpg",
          mediaType: null,
          video: null, 
          img: ["/img/novakrog/new_final_look_hp.png", "/img/novakrog/new_final_look_mob2.jpg"],
          overview: "A showcase of my portfolio, presenting graphic and web design projects. This involves the redesign and creation of a dynamic React website from scratch, with updated features allowing the addition of new portfolio items to the existing site.",
        
          // Optional Section: Design
          // design: "UX Design", 
          // design_subtitle: "User-friendly and appealing structure",
          // design_img: [
          //   "/img/novakrog/Uxplaninage1_480_550.jpg",
          //   "/img/novakrog/Uxplaninage2_488_550.jpg"
          // ],
          design_descript: "The design focuses on creating a clear and user-friendly structure. Simple layouts were planned using graphic elements to visualize how content and navigation would work together, ensuring the website is easy to use and visually appealing for all visitors.",
        
          // Optional Section: Features
          features: "Web Features",
          features_subtitle: "Functionalities and animations",
          features_img: ["/img/features.jpg"],
          features_descript: "The project includes an easy-to-update portfolio system built using React and Redux. The homepage features a 3D environment with a 3D logo created using Three.js (WebGL). Smooth animations, incorporating SVG, CSS, and JavaScript, are triggered by scroll interactions to enhance interactivity. Quick carousel navigation and pagination were designed to allow seamless access to portfolio items from the overview page. A pure CSS Flexbox approach ensures the site is fully responsive across most screen sizes.",
        
          // Optional Section: Technology
          technology: "Technology",
          technology_subtitle: "Web Development and Visualization Tools",  
          tech_descript: "Web: JavaScript, React, Redux, Three.js  Visual: Figma, Blender",
          tech_img: [
            "/img/tech/logo_JS.jpg", 
            "/img/tech/logo_React.jpg", 
            "/img/tech/logo_Redux.jpg", 
            "/img/tech/logo_Threejs.jpg", 
            "/img/tech/logo_Figma.jpg", 
            "/img/tech/logo_Blender.jpg"
          ],
        },
        
        { id: 2,
          name: "Drive Hub", 
          quickname:"DriveHub",   
          title: "Drive HUB",
          subtitle: "Driving School Application",
          type:"web",
          // thumb_video:"/img/drivehub_thumb.mp4",
          thumb_subtitle:"Driving school platform", 
          thumb_img:"/img/thumb_DriveHub.jpg",
          quick_nav_img:"/img/thumb_DriveHub.jpg",
          mediaType:"null",
          video:"null",
          img:"/img/drivehub/Dhub_top_page_975_550.jpg",
          overview:"Drive HUB is a team-based full-stack project developed at Constructor Academy, Zurich. This application enables driving students to find instructors, book lessons, and manage their schedules seamlessly through an interactive platform.",       
         

          // design: " ", 
          // design_subtitle: " ",
          // design_img: " ",
          // design_descript: " ",    


          features:"Features",
          features_subtitle:"Streamlined Booking Experience",
          features_descript:"The application offers an intuitive interface for students to search for driving instructors, check availability, and book lessons via a calendar. Students can manage their bookings through their profiles, while instructors can create lesson slots, advertise their services, and showcase their car and school profiles. This full-stack project involved both frontend and backend development, completed collaboratively by a team.", 
          features_img: "/img/drivehub/Dhub_feautures_975_463.jpg",

          technology:'Technology',
          technology_subtitle:"Full-Stack Development Tools",
          tech_img:["/img/tech/logo_JS.jpg", "/img/tech/logo_React.jpg", "/img/tech/logo_Redux.jpg", "/img/tech/Sass_trans.png", "/img/tech/python_trans.png", "/img/tech/django_green.png", ],  
          tech_descript:'Frontend: JavaScript, React, Redux, Sass, Backend: Python, Django,',


        }, 
     
          
       
        { id: 3,
          name: "VDAY BASEL",
          quickname:"Vday",   
          title: "VDAY Website",
          subtitle: "V. Monologues - web project",
          type:"web",
          thumb_subtitle:"Community web project",
          thumb_video:"/img/newVdayThumb60_2sec.mp4",
          quick_nav_img:"/img/vday_thumb.jpg",
          //thumb_img:"img/thumb_web4.jpg", 
          mediaType:"video",
          img:"null",
          img:"/img/vday/Vday_product_author.jpg",
          video:"/img/vday/overviewVday12sec_60.mp4",
          overview:"V-Day Basel is a community initiative launched for the first time in Basel to support V-Day, a global movement working to end violence against women and girls. The project aimed to raise awareness and foster a sense of solidarity within the local community.",
         
          // design: "UX design", 
          // design_subtitle:"Subtitle for Fetures",
          // design_img: [
          //     "/img/drivehub/Dhub_Ux1_480_550.jpg",
          //     "/img/drivehub/Dhub_Ux2_480_550.jpg",
          //   ],
          // design_descript: "Customers aren't the only ones who don't want to get lost on your website. Search engines need to be readily able to crawl your site and understand what it's about. One way to do this is by creating a site map, or a structured list of every page in a hierarchical format in order to help with SEO. It's also important to have internal links so that your site is more navigable from page to page. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.", 
          
          features:"Web Features",
          
          features_subtitle:'Key functionalities of the website',
          
          features_descript:"The website was designed to facilitate crowdfunding and online ticket sales for the Vagina Monologues performance. Built using JavaScript and Bootstrap, it also features:  a fully responsive design for seamless viewing on mobile and desktop devices, a dynamic landing page and video gallery to enrich the user experience.",
          
          technology:'Technology',
          technology_subtitle:"Development tools and technologies",  
          
          tech_descript:'JavaScript, Bootstrap, HTML, CSS',
          tech_img:["/img/tech/logo_JS.jpg", "/img/tech/bootstrap_two_w.jpg", "/img/tech/html.png", "/img/tech/css.jpg" ],

          // final:'Final',
          // final_subtitle:"Subtitle for Final",    
          // final_descript:'forntend: JS, React, ReactThreeFiber, Python, Django, Figma, Blender. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.',
          // final_img:["/img/vday/Vday_final1_480_550.jpg", "/img/vday/Vday_final2_480_550.jpg"]
        }, 

        // { id: 5, name: "ZODIAC", thumb_img:"/img/thumb_web5.jpg", type:"web"},
        
    // { id: 1, name: "Portfolio", thumb_img:"/img/thumb_web1.jpg", type:"web" },
    { id: 4,
        name: "Zodiac web site", 
        quickname:"Zodiac",  
        title: "Zodiac Web app",
        subtitle: "An interactive guide - Zodiac",
        type:"web",
        thumb_subtitle:"Interactive star guide",
        thumb_video:"/img/newzodiacThumb60_6sec.mp4",
        quick_nav_img:"/img/zoidiac_thumb.jpg",
        mediaType:"video",
        img:"null",
        video:"/img/zodiac/overviewZodiac12sec60.mp4",
        img:["/img/zodiac/zodiac_top_img_975_550.jpg"],
        overview:"This interactive website showcases all 12 Zodiac signs, dynamically updating based on user input, such as the submission of a birthday date. This is a simple web project designed to demonstrate interactive functionality.",
        
        // design_subtitle:"Subtitle for Features",
        // design_img: [
        //     "/img/novakrog/Uxplaninage1_480_550.jpg",
        //     "/img/novakrog/Uxplaninage2_488_550.jpg"
        //   ],
        // design_descript: "Customers aren't the only ones who don't want to get lost on your website. Search engines need to be readily able to crawl your site and understand what it's about. One way to do this is by creating a site map, or a structured list of every page in a hierarchical format in order to help with SEO. It's also important to have internal links so that your site is more navigable from page to page. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.", 
        
        features:"Web Features",
        features_subtitle:"Core features of the website",
        features_descript:"Fully responsive design, compatible with various devices and screen sizes. Form validation upon user submission for a smooth and error-free experience.", 
        // features_img: "/img/novakrog/WEB_feautures_975_463.jpg",

        technology:'Technology',
        technology_subtitle:"Development and technologies",  
        tech_descript:'JavaScript, jQuery, HTML, CSS',
        tech_img:["/img/tech/logo_JS.jpg", "/img/tech/jquery_lw.jpg", "/img/tech/html.png", "/img/tech/css.jpg" ],

        final:'Final',
        final_subtitle:"Subtitle for Final",    
        final_descript:'forntend: JS, React, ReactThreeFiber, Python, Django, Figma, Blender. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.',
        final_img:["/img/zodiac/zoidac_final1.jpg", "/img/zodiac/zoidac_final2.jpg"]
        }, 

        // { id: 6, name: "MUZE ", thumb_img:"/img/thumb_web6.jpg", type:"web"},

        { id: 5,
          name: "MUZE",
          quickname:"Muze",   
          title: "MUZE web site",
          subtitle: "Cultural event platform",
          type:"web",
          thumb_subtitle:"Cultural event platform",
          thumb_video:"/img/muzeThumbloop.mp4",
          quick_nav_img:"/img/muze_thumb.jpg", 
          mediaType:"video",
          img:"null",
          // thumb_img:"/img/thumb_web6.jpg",
          img:["/img/muze/Muze_web_top_img_975_550.jpg"],
          // video:"/img/muze/Muze60_1700kb.mp4",
          video:"/img/muze/overviewMuze11sec_60.mp4",
          
          overview:" The Muze Cultuur Centrum organizes concerts, theater, comedy, and kids' activities throughout the year. The main purpose of this website is to showcase their agenda, providing informative and structured content for visitors.",
          // design: "UX design", 
          // design_subtitle:"Subtitle UX / PLAN",
          // design_img: [
          //     "/img/muze/MuzeUxplaninage1_480_550.jpg",
          //   ],
          // design_descript: "Customers aren't the only ones who don't want to get lost on your website. Search engines need to be readily able to crawl your site and understand what it's about. One way to do this is by creating a site map, or a structured list of every page in a hierarchical format in order to help with SEO. It's also important to have internal links so that your site is more navigable from page to page. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.", 
          
          features : "Website Capabilities",
          features_subtitle:"Functionality and easy management",
          features_descript:"Developed with the CMS Drupal to allow content management via an admin/user login system. Includes a self-update feature, enabling users to modify and update content and the event agenda. Features a responsive design with a filter function to sort programs by type (e.g., concerts, theater, etc.).", 
          features_img: "/img/muze/Muze_web_features.png",

          technology:'Technology',
          technology_subtitle:"Development and technologies",  
          tech_descript:'CMS Drupal, PHP, Java Script, jQuery, HTML, CSS',
          tech_img:["/img/tech/drupal.png" ,"/img/tech/php.jpg", "/img/tech/logo_JS.jpg", "/img/tech/jquery_lw.jpg", "/img/tech/html.png", "/img/tech/css.jpg" ],

          // final:'Final',
          // final_subtitle:"Subtitle for Final",    
          // final_descript:'forntend: JS, React, ReactThreeFiber, Python, Django, Figma, Blender. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.',
          // final_img:["/img/muze/Muze_final_1.jpg", "/img/muze/Muze_final_2.jpg"],
        }, 


        
        { id: 6,
          name: "MAYFORDS",
          quickname:"Mayfords",   
          title: "Mayfords Website",
          subtitle: "A layout design for real estate solutions",
          type:"web",
          // thumb_video:"/img/drivehub_thumb.mp4", 
          thumb_subtitle:"Real estate web layout",
          thumb_img:"/img/maythumb.jpg",
          quick_nav_img:"/img/maythumb.jpg",
        
          img:"/img/mayfords/may_hpage_975_550.jpg",
          overview:"Designed a green-themed website layout specifically tailored for a real estate platform. A contribution in a team project by creating a functional HTML and CSS layout and assist PHP team with implementation.",
          // design: "UX design", 
          // design_subtitle:"Subtitle UX / PLAN",
          // design_img: [
          //     "/img/mayfords/sale_975_550_img.jpg",
          //   ],
          // design_descript: "Customers aren't the only ones who don't want to get lost on your website. Search engines need to be readily able to crawl your site and understand what it's about. One way to do this is by creating a site map, or a structured list of every page in a hierarchical format in order to help with SEO. It's also important to have internal links so that your site is more navigable from page to page. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.", 
          
          features:"Web Features",
          features_subtitle:"Structured and versatile layout",
          features_descript:"Designed both horizontal and vertical navigation menus to offer flexibility for the final implementation. Created a responsive layout, ensuring adaptability to different screen sizes.", 
          features_img: "/img/mayfords/morgage_975_550.jpg",
          technology:'Technology',
          technology_subtitle:"Development tools and technologies",  
          tech_descript:'HTML, CSS, JavaScript, jQuery,',
          tech_img:["/img/tech/html.png", "/img/tech/css.jpg", "/img/tech/logo_JS.jpg", "/img/tech/jquery_lw.jpg" ], 
        }, 

        { id: 7, name: "Octimet",
          quickname:"Octimet",
          title:"Octimet logo",
          subtitle:"Logo Design Concept for a Pharma Startup",
          thumb_subtitle:"Pharma logo concept",
          thumb_img:"/img/thumb_logo1.jpg",
          quick_nav_img:"/img/thumb_logo1.jpg",
          type:"logo",
          img:"/img/octimet/Octimet_color.jpg",
          overview:"Logo design concept developed for Octimet, a small pharma spin-off company with licensed compounds from Johnson & Johnson / Janssen Pharmaceutica NV.",
          design: "Logo design", 
          design_subtitle:"Design",
          design_img:["/img/octimet/may_hpage_975_550-16px.jpg", ],
          design_descript: "The company name integrates two gene names, Oct and Met, with the letter 'i' symbolizing immunology. These elements shaped the logo, representing the company's focus. The logo includes two versions: a black-and-white variant and a cyan-magenta color scheme, used across print and web media.", 
          technology:'Technology',
          tech_descript:'Adobe Illustrator & Photoshop',
          tech_img:["/img/tech/ill_trans.png","/img/tech/PS_trans.png"],
          // tech_img:"/img/novakrog/",
        },

     
        
        { id: 8, name: "Logo Designs",
          title:"Logos designs for Envato",
          quickname:"Logos", 
          thumb_subtitle:"Diverse logo projects",
          thumb_img:"/img/thumb_logo3.jpg",
          quick_nav_img:"/img/thumb_logo3.jpg",  
          type:"logo",
          subtitle:"Three Logo Design Concepts",
          img: "/img/logos/top_logos_sound_holliiday_pencil_b3.jpg",
          overview: "A set of three diverse logo designs created for freelance and microstock projects, primarily for Envato: New Sounds: Inspired by iPod, symbolizing innovative sound. Holiday Tours: Designed for a travel agency specializing in summer vacations. Creative Studio: A pencil symbolizing creativity, tailored for a design agency offering original solutions from scratch.", 
          technology:'Technology',
          tech_descript:'CorelDRAW, Adobe Illustrator, Photoshop and Huion Tablet for sketching',
          tech_img:["/img/tech/corel.png", "/img/tech/ill_trans.png", "/img/tech/PS_trans.png", "/img/tech/huion.jpg"],
        },
        
        
          // { id: 9, name: "Creatie Pencil", thumb_img:"/img/thumb_logo3.jpg", type:"logo"},
        // { id: 10, name: "Creatie Pencil", thumb_img:"/img/thumb_logo3.jpg", type:"logo"},
        { id: 9, name: "NovakRog",
          quickname:"NovakRog", 
          thumb_subtitle:"Personal animated logo",
          thumb_svgComponent:"NRSvg",
          quick_nav_img:"/img/NRlogo_thumb.jpg", 
          type:"logo",
          title:"NR logo",
          subtitle:"Personal Logo ",
          img:"/img/logos/NR_toppage_975_550.jpg",
          overview:"The logo, based on the initials 'N' and 'R,' was designed in two color schemes: black-and-white, as well as a light cyan version. It is versatile and suitable for various media. The design is used for 2D SVG animations and is being developed into a 3D animation for the homepage.",
          
          // design: "Logo design", 
          // design_subtitle:"Svg Animation",
          // design_img:["/img/logos/NR_toppage_975_550.jpg", ],
          technology:'Technology',
          tech_descript:'Huion Tablet for sketching, Adobe Illustrator, CSS and JavaScript animation',
          tech_img:["/img/tech/huion.jpg", "/img/tech/ill_trans.png", "/img/tech/PS_trans.png", ],
        }, 
      
        
        // { id: 12, name: "Drive Hub", thumb_img:"/img/thumb_logo5.jpg", type:"logo"},


        { id: 10, name: "New Cubes Brochure",
          quickname:"New Cubes",
          thumb_subtitle:"Architectural marketing design", 
          thumb_img:"/img/thumb_print1.jpg",
          quick_nav_img:"/img/thumb_print1.jpg", 
          type:"print",
          
          title: "New Cubes Brochure",
          subtitle:"Brochure Design for Architectural Marketing",
          overview: "Designed as a promotional A5 brochure format for architecture and construction purpose. This concept also suits interior design and other related fields.",
          img:"/img/newcubes/newcumbes_img1.jpg",
          design: "Brochure format", 
          design_subtitle:"Layout",
          design_img: [
              "/img/newcubes/newcumbes_img2.jpg",
            ],
          design_descript: "A fully customizable A5 horizontal layout includes photos, vector backgrounds, unique icons design including logo.", 
  
          technology:"Technology",
          technology_subtitle:"Software used:",
          tech_descript:'InDesign, Illustrator, and Photoshop',
          tech_img:["/img/tech/ID.png", "/img/tech/ill_trans.png", "/img/tech/PS_trans.png", ],
        },

        
        
          { id: 11, 
            name: "E-Marketing",
            quickname:"Marketing",
            thumb_subtitle:"E-commerce brochure design",
            title:"Marketing Brochure",
            type:"print",
            thumb_img:"/img/thumb_print2.jpg", 
            quick_nav_img:"/img/thumb_print2.jpg",    
            subtitle:"A5 Brochure Design for E-Commerce",
            img:"/img/marketing/marketing_1.jpg",
            overview:"Cherry-themed, stylish A5 brochure is split into eight sections, showcasing various aspects of e-commerce presented via print media.",
            design: "Brochure design", 
            design_subtitle:"New way of presenting",
            design_img: [
              "/img/marketing/marketing_2.jpg",
            ], 
            design_descript: "The design includes a cartoon-style vector mascot in a set of different poses, symbolizing each content section.",             
            technology:'Technology',
            technology_subtitle:"Technology used:",  
            tech_descript:'InDesign, Illustrator, and Photoshop',
            tech_img:["/img/tech/ID.png", "/img/tech/ill_trans.png", "/img/tech/PS_trans.png", ],
          },
        
          { id: 12, 
          name: "Sesonal Tri-fold",
          quickname:"Tri-fold", 
          title: "Trifold Broshure",
          subtitle:"Autumn-Themed Trifold Design",
          thumb_subtitle:"Autumn trifold layout",
          thumb_img:"/img/thumb_print3.jpg",
          quick_nav_img:"/img/thumb_print3.jpg",  
          type:"print",
          img:"/img/season/Seasonal_front.jpg",
          overview:"An autumn-themed trifold layout created primarily as a freelance project for Envato. Designed to target customers in the fashion industry seeking elegant and customizable brochure solutions.", 
          design: "Design", 
          design_subtitle:"Design process",
          design_img: [
              "/img/season/Seasonal_InDesign.jpg",
            ],
          design_descript: "A customizable trifold brochure created for the autumn season. It features two layout color versions, logos, vectors, and photographs.", 
          technology:"Technology",
          technology_subtitle:"Technology used:",
          tech_descript: "Designed using Adobe InDesign, Illustrator and Photoshop",
          tech_img:["/img/tech/ID.png", "/img/tech/ill_trans.png", "/img/tech/PS_trans.png", ],
        },


        // { id: 15, name: "Fastfood Menu", thumb_img:"/img/thumb_print4.jpg", type:"print"},
        { id: 13, name: "Restaurant Menu",
          quickname:"Menu",
          thumb_subtitle:"Creative menu design", 
          thumb_img:"/img/thumb_print5.jpg", 
          quick_nav_img:"/img/thumb_print5.jpg", 
          type:"print",
          img:"/img/restaurant/Restcoverpage_back2.jpg",
          title:"Restaurant Menu Brochure",
          subtitle:"A5 Restaurant Menu Design",
          overview:"A 12-page cartoon-style menu created for restaurant businesses. It includes five submenus: Drinks, Sandwiches, Soups & Pasta, Lunch, and Desserts. The design is adaptable for various restaurant specifications.",
          
          design: "Brochure design", 
          design_subtitle:"Brochure format",
          
          design_img:["/img/restaurant/Rest_design.jpg"],
          design_descript: "Layout: A5 format (148×210mm), CMYK color, 300 DPI resolution. Mascot vector images included and easy to edit for other projects. Designed from scratch using Adobe InDesign and Illustrator", 
         
          technology:"Technology",
          technology_subtitle:"Software used:",
          tech_descript: "Designed using Adobe InDesign, Illustrator, Photoshop",
          tech_img:["/img/tech/ID.png", "/img/tech/ill_trans.png", "/img/tech/PS_trans.png", ],
          // features:"Web Features",
          // features_subtitle:"Subtitle for Fetures",
          // features_descript:"Intuitive Navigation: Global horizontal Main Navigation and custom made vertical Scroll Indicator and Navigator for Overview Items. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.", 
          // features_img: "/img/restaurant/restaraunt_InDesign_975_550.jpg",

          // final:'Final',
          // final_subtitle:"Subtitle for Final",    
          // final_descript:'forntend: JS, React, ReactThreeFiber, Python, Django, Figma, Blender. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section 1.10.32.',
          // final_img:"/img/restaurant/Rest_desert_final1.jpg",
        },

        // { id: 17, name: "Restaraunt Menu", 
        //   title:"",
        //   subtitle:"",
        //   overview:"",
        //   thumb_img:"/img/thumb_print4.jpg", 
        //   type:"print",
        //   design:"Design",
        //   design_subtitle:"Layout",
        //   design_descript:"Layout: A5 format (148×210mm), CMYK color, 300 DPI resolution. Mascot vector images included and easy to edit for other projects,Designed from scratch using Adobe InDesign and Illustrator",
        //   design_img:[""],

        // },

        // { id: 17, name: "Vita Science", thumb_video:"img/forest_thumb.mp4", video:"/img/forest_thumb.mp4", type:"animation" },
        
        { id: 14, name: "Illustrations", 
          title:"Illustrations",
          quickname:"Art",
          thumb_subtitle:"Hand-drawn and vector art",
          subtiitle:"Vector Portrait",
          thumb_img:"/img/thumb_ill1.jpg",
          quick_nav_img:"/img/thumb_ill1.jpg", 
          mediaType: "null",
          video:"null",
          type:"illustration",
          img:"/img/art/topimg_woman_975_550.jpg",
          overview:"Minimalist vector-style illustration of a Black woman, created for AdobeStock and Shutterstock galleries.",
          
          design: "Still Life", 
          design_subtitle:"Hand-Drawn Artwork",
          design_img:["/img/art/novakrog_henddrawing.jpg"],
          design_descript: "Still-life hand-drawn illustrations displayed in exhibitions, focusing on organic, close-up details. Material used: pencil and paper", 
          
          features:"Artworks",
          features_subtitle:"Surreal Compositions",
          features_descript:"A collection of surreal-inspired vector illustrations for AdobeStock and Shutterstock, showcasing diverse artistic elements.", 
          features_img: "/img/art/ill_design_975_550.jpg",

          technology:"Fashion sketches",
          technology_subtitle:"Drawings made on an Ipad",
          tech_descript:"Fashion illustrations created on an iPad sketchbook, designed specifically for a fashion brochure project. These illustrations were tailored to enhance the brochure's visual appeal and align with modern design trends.", 
          tech_img:"/img/art/fash_ill_sketchy.jpg",
          // tech_img:["/img/tech/sketchbook.jpg", "/img/tech/ill_trans.png", "/img/tech/corel.png", "/img/tech/PS_trans.png"],
        },

        { id: 15, name: "Vita Science",
          quickname:"Vita", 
          title:"VitaSupport Animation",
          subtitle:"Scientific Video Animated Project",
          quick_nav_img:"/img/vita_thumb.jpg",
          thumb_subtitle:"Scientific animation project",
          thumb_video:"/img/forest_thumb.mp4",
          // video:"/img/forest_thumb.mp4", 
          img:"/img/vita/hpage_975_550.jpg",
          type:"animation", 
          overview:"A scientific animation developed for presentations, demonstrating a method to prevent Eco Sysytem from spreading forest fires.",
          design: "After Effects Animation", 
          // design_subtitle:"Subtitle for Animation",
          design_img:["/img/vita/vita_AE_975_550.jpg"],

          technology:"Technology",
          technology_subtitle:"Software used",
          tech_descript: "Designed using Adobe After Effects, Premiere, Illustrator and Photoshop",
          tech_img:["/img/tech/AE.png", "/img/tech/pr_trans.png", "/img/tech/ill_trans.png","/img/tech/PS_trans.png", ],
        },



        // { id: 18, name: "Scientific animation", thumb_img:"/img/transparent.png", type:"animation"},
        // { id: 18, name: "Illustrations", thumb_img:"/img/thumb_ill1.jpg", type:"illustration"},
        // { id: 20, name: "Illustrations", thumb_img:"/img/transparent.png", type:"illustration"}
    ], 
    filteredItems:[],  
}
// create slice with Reducer //



const itemsSlice = createSlice({
    name: "items",
    initialState,
    reducers:{
       filterItems:(state, action) => {
        //put payload.type (in our case is named type) into a const
        const type = action.payload.type
        // if else satetment //
        if (type === 'all'){
            state.filteredItems = state.items /* we use local state  */
        } else {
            state.filteredItems = state.items.filter(item => item.type === type )
        }     
    }  
    }});


// export reducer action //
export const { filterItems } = itemsSlice.actions;
export default itemsSlice.reducer;