import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filterItems } from '../../app/slice_with_reducer/itemsSlice';
import { NavLink } from 'react-router-dom';
import NRSvg from '../svg/nrsvg';
import './itemsList.css';

const ItemsList = () => {
  const [deviceType, setDeviceType] = useState("desktop");
  const [filterKey, setFilterKey] = useState(0);
  
  const componentMap = {
    NRSvg: NRSvg,
  };

  const items = useSelector((state) => state.items.filteredItems);
  const dispatch = useDispatch();
  const itemRefs = useRef({});

  useEffect(() => {
    dispatch(filterItems({ type: "all" }));
  }, [dispatch]);

  const handleFilter = (type) => {
    dispatch(filterItems({ type }));
    setFilterKey((prevKey) => prevKey + 1);
  };

  const chunkItems = (items, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < items.length; i += chunkSize) {
      chunks.push(items.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const groupedItems = chunkItems(items, 2);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const targetElement = entry.target;
  
          // Fade in and play video when in view
          if (entry.isIntersecting) {
            targetElement.classList.add("fadeIn");
  
            // Play video if the target is a video element
            const video = targetElement.querySelector("video");
            if (video) {
              video.muted = true; // Ensure video is muted for autoplay
              video.play().catch((error) => {
                console.log("Autoplay failed:", error); // Log autoplay issues if any
              });
            }
          } else {
            targetElement.classList.remove("fadeIn");
  
            // Pause video when out of view
            const video = targetElement.querySelector("video");
            if (video) {
              video.pause();
              video.currentTime = 0; // Reset video to start when out of view
            }
          }
        });
      },
      { threshold: 0.2 } // Adjust threshold to trigger visibility at the desired point
    );
  
    // Observe each item container
    Object.values(itemRefs.current).forEach((ref) => {
      if (ref) observer.observe(ref);
    });
  
    return () => observer.disconnect();
  }, [items, filterKey]);
  
  

  const renderMedia = (item) => {
    if (item.thumb_img && !item.thumb_svgobj) {
      return <img src={item.thumb_img} alt={item.name} />;
    } else if (item.thumb_video) {
      return (
        <video 
          src={item.thumb_video} 
          loop={false} 
          muted
          playsInline // Prevents fullscreen on iOS - very importand 
          ref={(el) => (itemRefs.current[item.id] = el ? el.parentNode : null)} 
        />
      );
    } else if (item.thumb_svgComponent) {
      const SvgComponent = componentMap[item.thumb_svgComponent];
      return SvgComponent ? <SvgComponent mode={deviceType} /> : null;
    } else {
      return null;
    }
  };

  return (
    <>
    
      {/* <div className="mainFolioTitle">
        <h3>Digital Projects Showcase</h3>
        <h4>Discover and filter by project type</h4></div> */}

      <div className="pagetitle">
        <h3>Digital Projects Showcase</h3>
        <h4>Discover and filter by project type</h4>
      </div>  
      <div className='foliofilter'>
        <div></div>
        <div> 
          <button onClick={() => handleFilter('all')}>All</button>
          <button onClick={() => handleFilter('web')}>Web</button>
          <button onClick={() => handleFilter('logo')}>Logo</button>
          <button onClick={() => handleFilter('print')}>Print</button>
          <button onClick={() => handleFilter('animation')}>Animation</button>
          <button onClick={() => handleFilter('illustration')}>Illustration</button>
        </div>
        <div></div>
      </div>

      <div className="wrapitemlist" id="wrapitems">
      
        <main className="foliocontent" key={filterKey}>
          <div className="foliothumb">
            {groupedItems.map((group, index) => (
              <div key={index} className="thumbs">
                {group.map((item) => (
                  <div 
                    key={item.id} 
                    className="thumbsimages" 
                    ref={(el) => itemRefs.current[item.id] = el} 
                    id={item.id}
                  >
                    <NavLink to={`/overview/${item.id}`} style={{ cursor: 'pointer', display: 'inline-block' }}>
                      {renderMedia(item)}
                    </NavLink>
                    <div className="thumbheading">
                      <h2>{item.name}</h2>
                      <h4>{item.thumb_subtitle}</h4>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>        
        </main>          
      </div>
    </>
  );
};

export default ItemsList;
