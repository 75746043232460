import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import './quicknavigation.css';

const QuickNavigation = ({ navOpen }) => {
  const { id } = useParams(); 
  const quickitems = useSelector((state) => state.items.items); 

  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const currentItem = quickitems.find((item) => item.id === parseInt(id));
  const currentType = currentItem?.type || '';

  const sortedItems = quickitems.slice().sort((a, b) => {
    if (a.type === currentType && b.type !== currentType) return -1;
    if (a.type !== currentType && b.type === currentType) return 1;
    return 0;
  });

  const handleNext = () => {
    if (currentIndex + itemsPerPage < sortedItems.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (currentIndex >= itemsPerPage) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

useEffect(()=>{
  const handleResize = () => {

    if ( window.innerWidth <= 932){
        setItemsPerPage(4);  
      } else {
        setItemsPerPage(6);
      } 
    };

    handleResize();
    // Add resize event listener
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    }; 
},[]); 


  return (
    // <div className="quick-navigation">
    <div className={`quick-navigation ${navOpen ? 'nav-open' : ''}`}>
      <div className="carousel-container">
        <button className="arrow left" onClick={handlePrev}>&lt;</button>
        <div className="quick-nav-horizont" style={{ transform: `translateX(-${currentIndex * (100 / itemsPerPage)}%)`, width:'95%' }}>
          {sortedItems.map((item) => (
            <div key={item.id} className="quick-nav-item">
              <Link to={`/overview/${item.id}`} className={item.id === parseInt(id) ? 'active' : ''}>
                <img src={item.quick_nav_img} alt={item.name} />
                <p>{item.quickname}</p>
              </Link>
            </div>
          ))}
        </div>
        <button className="arrow right" onClick={handleNext}>&gt;</button>
      </div>
    </div>
  );
};

export default QuickNavigation;
