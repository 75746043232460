import React from 'react';
import './footer.css';

const Footer = ({className}) => {
    
    return(
      <>
        <footer className={className}>
           <div className="attribution"> 
                <p><span className='codedesign'>Code & design |</span> Copyright © 2025 novakrog.ch </p>
            </div>
            <div className="contact">
                <p> +41 77 520 46 81 | contact@novakrog.ch </p> 
            </div>    
        </footer>
    </>
    );
}

export default Footer;

