import * as THREE from 'three';
import React, { useRef, useEffect, useState } from 'react';
import { useGLTF, useAnimations} from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Text, Billboard } from '@react-three/drei';
import DMFontTTF from '../../fonts/BebasNeue-Regular.ttf';
import VarelaFont from '../../fonts/Varela-Regular.ttf';
import CabinetexBold from '../../fonts/newfonts/CabinetexBoldSatoshiReg/CabinetGroteskExtrabold.ttf';
import SatoshiRegular from '../../fonts/newfonts/CabinetexBoldSatoshiReg/SatoshiRegular.ttf';

import ClashDisplaySemibold from '../../fonts/newfonts/ClashDisplaySemiboldRegular/ClashDisplaySemibold.ttf';
import ClashDisplayRegular from '../../fonts/newfonts/ClashDisplaySemiboldRegular/ClashDisplayRegular.ttf';

import GeneralSansSemibold from '../../fonts/newfonts/GeneraSansSemiBoldRegular/GeneralSansSemibold.ttf';
import GeneralSansRegular from '../../fonts/newfonts/GeneraSansSemiBoldRegular/GeneralSansRegular.ttf';


import GeneralSansSemiBoldRegular from '../../fonts/newfonts/GeneraSansSemiBoldRegular/GeneralSansRegular.ttf';

const GLBModelmobile = (props) => {
  const group = useRef();
  const gltf = useGLTF('/glb/NRonlylogo2.glb', true);
  const { actions } = useAnimations(gltf.animations, group);
  const mixer = useRef(null);
  const actionRef = useRef(null);
  const [playedOnce, setPlayedOnce] = useState(false);
  const emptyGroup = useRef(null); // Reference for 'Empty' group

  // Apply custom materials and colors to each mesh in the glTF scene
  useEffect(() => {
    if (gltf.scene) {
      gltf.scene.traverse((node) => {
        if (node.isMesh) {
          node.castShadow = true;
          node.receiveShadow = true;

          if (node.name === 'Curve001') {
            node.material = new THREE.MeshPhysicalMaterial({
              emissive: '#000000',
              roughness: 0.542,
              metalness: 0.529,
              ior: 1.740,
              reflectivity: 0.765,
              iridescence: 1,
              iridescenceIOR: 1.230359,
              clearcoat: 0.55,
              clearcoatRoughness: 0,
              specularIntensity: 1.0,
              specularColor: '#FFFFFF',
              color: new THREE.Color(0.129, 0.008, 0.251),
              envMap: new THREE.CubeTextureLoader().load([
                '/img/px.jpg',
                '/img/nx.jpg',
                '/img/py.jpg',
                '/img/ny.jpg',
                '/img/pz.jpg',
                '/img/nz.jpg',
              ]),
            });
          } else if (node.name === 'Curve002') {
            node.material = new THREE.MeshPhysicalMaterial({
              color: new THREE.Color(0.118, 0.596, 0.729),
              roughness: 0.4,
              metalness: 0.5,
            });
          } else if (node.name === 'Curve003') {
            node.material = new THREE.MeshPhysicalMaterial({
              color: new THREE.Color(0.522, 0.063, 0.475),
              roughness: 0.4,
              metalness: 0.5,
            });
          } else if (node.name === 'Curve004') {
            node.material = new THREE.MeshPhysicalMaterial({
              color: new THREE.Color(0.467, 0.459, 0.502),
              roughness: 0.4,
              metalness: 0.5,
            });
          } else if (node.name === 'Curve005') {
            node.material = new THREE.MeshPhysicalMaterial({
               color: new THREE.Color(0, 0.933, 0.941),
              roughness: 0.4,
              metalness: 0.5,
            });
          }
        }
        // Store reference to the Empty group for rotation
        if (node.name === 'Empty') {
          emptyGroup.current = node;
        }
      });
    }
  }, [gltf.scene]);

  // Setup and play animation in reverse from 58% to 40% 
  useEffect(() => {
    if (gltf.animations && gltf.animations.length > 0 && !playedOnce) {
      mixer.current = new THREE.AnimationMixer(gltf.scene);
      const clip = gltf.animations[0];
      const action = mixer.current.clipAction(clip);
      actionRef.current = action;

      const duration = clip.duration;
      const startTime = 0.58 * duration;
      
      // Configure the animation to play in reverse from 58% to 40%
      action.time = startTime;
      action.setLoop(THREE.LoopOnce);
      action.clampWhenFinished = true;
      action.timeScale = -1; // Reverse playback
      action.play();
      setPlayedOnce(true);
    }
  }, [playedOnce, gltf]);

  // Update frame with rotation and reverse animation stop at 40%
  useFrame((state, delta) => {
    if (mixer.current && actionRef.current) {
      mixer.current.update(delta);
      const duration = actionRef.current.getClip().duration;
      const stopTime = 0.40 * duration;

      // Pause the animation at the 40% mark
      if (actionRef.current.time <= stopTime) {
        actionRef.current.time = stopTime;
        actionRef.current.paused = true;
      }
    }

    // Continuous rotation for the 'Empty' group
    if (emptyGroup.current) {
      emptyGroup.current.rotation.y -= 0.0017;
    }
  });

  return (
    <>
    <group ref={group} {...props} dispose={null}>
      <primitive object={gltf.scene}  scale={[.60, .60, .60]} position={[0, 2.35, 0]} />
    </group>
      <OrbitControls  enableZoom={true} 
            minDistance={10} 
            maxDistance={10} />    
    <Billboard>
     <group>
           <Text
                position={[-2.0, -6.80, 0]}
                fontSize={[0.19]}
                color="white"
                font={VarelaFont}
                textAlign="center"
              >
            Copyright © 2025 novakrog.ch                                 
            </Text>
      
              <Text 
                position={[1.55, -6.80, 0]}
                fontSize={[0.19]}
                color="cyan"
                font={VarelaFont}
                textAlign="center"
              >
            +41 77 520 46 81 | contact@novakrog.ch                                  
            </Text>

        <Text
          position={[0, -.5, -3]}
          fontSize={[0.65]}
          scale={[.8, .8, .8]}
          color="white"          
          font={CabinetexBold} 
          textAlign="center"
        >
        INOVATING DIGITAL EXPERIENCES
        {/* Inovating Digital Expeirences */}
        </Text>

        <Text
          position={[0, -1.3, -3]}
          fontSize={[0.33]}
          scale={[.8, .8, .8]}
          color="white"          
          font={SatoshiRegular}
          textAlign="center"
        >
         {"CRAFTING MODERN SOLUTIONS FOR WEB & INTERACTIVE MEDIA \n DELIVERING SEAMLESS PROJECTS WITH CUTTING-EDGE TECH IN \n FRONT-END DEVELOPMENT,  UX, GRAPHICS AND ANIMATIONS"}
        </Text>

        
        <Text
          position={[0, -5.3, -3]}
          fontSize={[0.35]}
          scale={[.8, .8, .8]}
          color="cyan"          
          font={SatoshiRegular}
          textAlign="center"
        >
        HAPPY NEW YEAR 2025!
        {/* Inovating Digital Expeirences */}
        </Text>


     </group>
     </Billboard>
    </>
  );
};

export default GLBModelmobile;
