import * as THREE from 'three';
import { useMemo, useRef } from 'react';
import { extend, useFrame } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';

// Define the custom shader material
const FogShaderMaterial = shaderMaterial(
  {
    uTime: 0,
    uColor: new THREE.Color(0x7f7f8d), // Customize fog color here
    uOpacity: 0.4,
  },
  // Vertex Shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
      gl_Position = projectionMatrix * modelViewPosition;
    }
  `,
  // Fragment Shader
  `
    uniform float uTime;
    uniform vec3 uColor;
    uniform float uOpacity;
    varying vec2 vUv;

    float random(vec2 st) {
      return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
    }

    float noise(vec2 st) {
      vec2 i = floor(st);
      vec2 f = fract(st);
      float a = random(i);
      float b = random(i + vec2(1.0, 0.0));
      float c = random(i + vec2(0.0, 1.0));
      float d = random(i + vec2(1.0, 1.0));
      vec2 u = f * f * (3.0 - 2.0 * f);
      return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }

    void main() {
      float strength = 1.0 - length(vUv - 0.5);
      float n = noise(vUv * 4.0 + vec2(uTime * 0.1));
      vec3 color = uColor * strength * n;
      gl_FragColor = vec4(color, uOpacity * strength);
    }
  `
);

extend({ FogShaderMaterial });

const MilkyWayFog = () => {
  const fogRef = useRef();

  useFrame((state) => {
    fogRef.current.uTime = state.clock.getElapsedTime();
  });

  return (
    <mesh position={[0, 0, -3]} scale={[3.5, 3, 1]}>
      <planeGeometry args={[10, 10, 1, 1]} />
      <fogShaderMaterial ref={fogRef} />
    </mesh>
  );
};

export default MilkyWayFog;
